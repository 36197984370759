<template>
  <div class="px-4">
    <v-card v-if="(!orderLoader && alertDetails.length > 0 && !alertSearch) || (!orderLoader && filterdata.length > 0 && alertSearch)" id="alert_module">
      <v-simple-table transition="fade-transition" :class="{ overflowinherit: isDropped }" id="alerts_table">
        <thead id="alerts_table_thead">
          <tr>
            <th class="text-left" id="alerts_table_thead_name">Name</th>
            <th class="text-left" id="alerts_table_thead_scrip_name">Scrip Name</th>
            <th class="text-center" id="alerts_table_thead_triggered">Triggered</th>
          </tr>
        </thead>
        <tbody id="alerts_table_tbody">
          <tr v-for="(item, i) of !alertSearch ? alertDetails : filterdata" :key="i" @mouseenter="currentIndex = i;"
            @mouseleave="currentIndex = -1" :id="`${i}_alerts_table_tbody_tr`">
            <td class="text-left" :id="`${i}_alerts_table_tbody_alert_name`">{{ item.alertName }}</td>
            <td class="text-left  pos-reletive" :id="`${i}_alerts_table_tbody_scrip_name`">{{ item.scripName }}
              <div class="dropdown position-absolute right-0  d-flex top-11" v-if="currentIndex == i">
                <label :id="`${i}_alerts_table_tbody_edit`" class="hoverBtn mr-2 " :class="$vuetify.theme.dark ? 'darkHover' : 'lightHover'" @click="$store.commit('order/setIsalertDialog', true);$store.commit('order/setEditAlert', item);$store.commit('order/setAlertFrom', null)">
                  <customIcon class="hoverImg d-flex align-center" :name="'editIcon'" :width="'20'" :height="'20'"
                    :color="$vuetify.theme.dark ? '#ffffff' :$store.state.iconBlackColourCode" />
                </label>
                <label :id="`${i}_alerts_table_tbody_delete`" class="hoverBtn mr-2 " :class="$vuetify.theme.dark ? 'darkHover' : 'lightHover'" @click="deleteAlert(item)">
                  <customIcon class="hoverImg d-flex align-center" :name="'delete'" :width="'20'" :height="'20'"
                    :color="$vuetify.theme.dark ? '#ffffff' : $store.state.iconBlackColourCode" />
                </label>
              </div>
            </td>
            <td class="text-center" :id="`${i}_alerts_table_tbody_trigger_status`">{{ item.triggerStatus }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>

    <div class="d-flex flex-column justify-center align-center vh-70" v-if="(!orderLoader && alertDetails.length == 0 && !alertSearch) || (!orderLoader && filterdata.length == 0 && alertSearch)">
      <div>
        <img src="../assets/images/noData.svg" alt="" class="w-300 h-170 mb-4" id="alert_no_data_img" />
      </div>
      <div class="primaryColor" id="alert_no_data_content">NO ALERT FOUND</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import customIcon from '../components/customIcon.vue'
export default {
  data() {
    return {
      isDropped: false,
      currentIndex: -1,
      filterdata:[]
    }
  },
  components: {
    customIcon
  },
  computed: {
    ...mapState("order", [
      "alertDetails",
      "orderLoader",
      "alertSearch"
    ]),
  },
  methods: {
    deleteAlert(currentAlertData) {
      this.$store.commit('order/setCurrentDeleteAlertDetails', currentAlertData)
      this.$store.commit('order/setIsDeleteDialog', true)
    }
  },
  watch:{
    alertSearch: function(val){
      if(val){
        this.filterdata = this.alertDetails.filter(el =>{
          return el.scripName.includes(val)
        })
      }else{
        this.filterdata = []
      }
    }
  }
}
</script>

<style>

</style>