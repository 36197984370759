<template>
  <div id="order_module">
    <div class="d-flex align-center px-4 py-2">
      <div class="font-weight-bold primaryColor" id="order_label">Orders</div>
      <v-progress-circular
        class="ml-2"
        indeterminate
        v-if="orderLoader"
        size="18"
        :width="2"
        color="blue"
      ></v-progress-circular>
    </div>

    <v-row class="mb-2 mx-0 mt-0 px-4">
      <v-slide-group
        v-model="infoType"
        class="pa-0"
        mandatory
        hide-arrows
        @change="callService(infoType)"
      >
        <v-slide-item
          v-for="(item, i) in tabList"
          :key="i"
          v-bind:value="item.name"
          v-slot="{ active, toggle }"
        >
          <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
            <template v-slot:activator="{ on, attrs }">
              <span
                @click="
                  $router
                    .push({
                      path: 'orders',
                      query: { tab: infoType.trim().toString() },
                    })
                    .catch(() => {})
                "
              >
                <v-btn
                  depressed
                  :color="
                    active
                      ? 'activeColor white--text'
                      : menuColors()
                  "
                  height="26"
                  min-width="50"
                  class="fsize12 mr-2 rounded-sm text-center text-capitalize"
                  :value="item.name"
                  @click="toggle"
                  v-bind="attrs"
                  v-on="on"
                  :id="`${item.name}_btn`"
                >
                  {{ item.name }}
                  <span v-if="item.length != 0"
                    >&nbsp; ({{ item.length }})</span
                  >
                </v-btn>
              </span>
            </template>
            <span>{{ item.toolTip }}</span>
          </v-tooltip>
        </v-slide-item>
      </v-slide-group>

      <v-spacer></v-spacer>
<span v-if="infoType =='Alerts'" class="d-flex">
      <v-btn
      @click="$store.commit('order/setIsalertDialog', true);$store.commit('order/setEditAlert', null);$store.commit('order/setAlertFrom', null)"
          depressed id="new_alert_btn"
          :color="$store.state.buttonThemeColor"
          width="120px"
          height="27px"
          :class="$store.state.windowWidth < 557 ? 'mt-2' : ''"
          class="flat text-capitalize fsize14 white--text"
          >New Alert</v-btn
        >
          <span
          class="search-box mx-2"
          v-if="!$store.state.isMobileView && this.alertDetails.length > 0"
        >
          <span class="searchwhole">
            <customIcon
              :name="'search'"
              class="searchimg"
              :width="'16'"
              :height="'16'"
              :color="$store.state.amogaColor"
            />
            <span class="searchinput">
              <input id="alertSearch"
                class="placeholdsearch"
                placeholder="Search"
                autocorrect="off"
                v-model.trim="$store.state.order.alertSearch"
                oninput="this.value = this.value.toUpperCase()"
              />
            </span>
          </span>
        </span>
     </span>
      <span v-else
        class="float-right d-flex align-center"
        id="searchBarAndIcons"
        :class="
          $store.state.windowWidth < 1173 && $store.state.windowWidth > 1023
            ? 'mt-2'
            : ''
        "
      >
        <v-btn id="new_basket_btn"
          @click="
            createBasketDialog = true;
            isEdit = false;
          "
          v-if="getInfoType == 'Baskets'"
          depressed
          :color="$store.state.buttonThemeColor"
          width="120px"
          height="27px"
          :class="$store.state.windowWidth < 557 ? 'mt-2' : ''"
          class="flat text-capitalize fsize14 white--text"
          >New Basket</v-btn
        >
        <span
          class="search-box mx-2"
          v-if="!$store.state.isMobileView && this.showOrderlist.length > 0 && !this.selectedBasketName"
        >
          <span class="searchwhole">
            <customIcon
              :name="'search'"
              class="searchimg"
              :width="'16'"
              :height="'16'"
              :color="$store.state.amogaColor"
            />
            <span class="searchinput">
              <input id="orderSearch"
                class="placeholdsearch"
                placeholder="Search"
                autocorrect="off"
                v-model="searchorder"
                oninput="this.value = this.value.toUpperCase()"
              />
            </span>
          </span>
        </span>
        <span v-if="this.showOrderlist.length > 0 && getInfoType != 'Baskets'">
          <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
            <template v-slot:activator="{ on, attrs }">
              <a id="a2">
                <span
                  v-bind="attrs"
                  v-on="on"
                  v-if="!$store.state.isMobileView"
                  @click="checkDownload(infoType, 'orders', searchorder ? orderSearchFilter : showOrderlist, '')" id="orders_download"
                >
                  <customIcon
                    :name="'download'"
                    class="mx-1 cursor d-flex"
                    :width="'21'"
                    :height="'21'"
                    :color="$store.state.amogaColor"
                  />
                </span>
              </a>
            </template>
            <span>Download</span>
          </v-tooltip>
        </span>
      </span>
    </v-row>
    <v-divider class="mx-4 mb-2"></v-divider>
     <div v-if="infoType =='Alerts'">
      <alert />
    </div>
    <div v-else>
    <v-card
      class="ma-4"
      v-if="
        (showOrderlist.length > 0 &&
          this.searchorder == '' &&
          !this.selectedBasketName &&
          !this.orderLoader) ||
        (orderSearchFilter.length > 0 &&
          this.searchorder !== '' &&
          !this.orderLoader &&
          !this.selectedBasketName)
      "
    >
      <v-simple-table
        v-if="!$store.state.isMobileView"
        transition="fade-transition"
        id="orders_table"
        :class="{ overflowinherit: isDropped }"
      >
        <thead id="orders_table_thead">
          <tr class="tableRow" id="orders_table_thead_tr">
            <th
              v-if="getInfoType == 'Pending'"
              class="text-left d-flex align-center"
            >
              <input id="orders_table_checkbox"
                type="checkbox"
                v-model="checkBoxSelectAll"
                class="tableCheckBox"
              />
            </th>
            <th class="text-left tableHeader" v-if="getInfoType != 'Baskets'" id="orders_table_time_thead">
              Time
            </th>
            <th class="text-center tableHeader" v-if="getInfoType != 'Baskets'" id="orders_table_type_thead">
              Type
            </th>
            <th class="text-left tableHeader" v-if="getInfoType != 'Baskets'" id="orders_table_instrument_thead">
              Instrument
            </th>
            <th class="text-left tableHeader" v-if="getInfoType != 'Baskets'" id="orders_table_product_thead">
              Product
            </th>
            <th class="text-right tableHeader" v-if="getInfoType != 'Baskets'" id="orders_table_qty_thead">
              Qty.
            </th>
            <th class="text-right tableHeader" v-if="getInfoType == 'Pending'" id="orders_table_ltp_thead">
              LTP
            </th>
            <th class="text-right tableHeader" v-if="getInfoType != 'Baskets'" id="orders_table_price_thead">
              Price
            </th>
            <th
              class="text-center tableHeader" id="orders_table_status_thead"
              v-if="getInfoType == 'Pending' || getInfoType == 'Executed'"
            >
              Status
            </th>
            <th class="text-left tableHeader" v-if="getInfoType == 'Baskets'" id="orders_table_Name_thead">
              Name
            </th>
            <th class="text-left tableHeader" v-if="getInfoType == 'Baskets'" id="orders_table_Items_thead">
              Items
            </th>
            <th class="text-left tableHeader" v-if="getInfoType == 'Baskets'" id="orders_table_create_on_thead">
              Create On
            </th>
            <th class="text-left tableHeader" v-if="getInfoType == 'Baskets'" id="orders_table_excuted_thead">
              Executed
            </th>
          </tr>
        </thead>
        <tbody id="orders_table_tbody"
          class=""
          @mouseleave="
            currentIndex = -1;
            isDropped = false;
          "
        >
          <tr :id="`${index}_orders_table_tbody_tr`"
            class="tableRow"
            :class="getInfoType == 'Baskets' ? 'cursor' : ''"
            v-for="(item, index) in this.searchorder == ''
              ? this.showOrderlist
              : orderSearchFilter"
            :key="index"
            @mouseenter="
              isDropped = false;
              currentIndex = index;
            "
            @click="getInfoType == 'Baskets' ? setBasket(item)  : ''"
          >
            <td class="px-4 py-2" v-if="getInfoType == 'Pending'" :id="`${index}_orders_table_tbody_check_box`">
              <input
                type="checkbox"
                v-model="selectedCheckbox"
                :value="item"
                class="tableCheckBox"
              />
            </td>

            <td :id="`${index}_orders_table_tbody_orderTime`"
              class="text-left primaryColor px-4 py-2 tableContent"
              v-if="getInfoType == 'Pending' || getInfoType == 'Executed'"
            >
              {{
                item.OrderedTime != null
                  ? item.OrderedTime.split(" ")[3]
                  : item.OrderedTime
              }}
            </td>
            <td :id="`${index}_orders_table_tbody_orderTime`"
              lass="text-left primaryColor px-4 py-2 tableContent"
              v-if="getInfoType == 'Trade Book'"
            >
              {{ item.Time.split(" ")[1] }}
            </td>
            <td :id="`${index}_orders_table_tbody_orderType`"
              class="text-center px-4 py-2 tableContent primaryColor"
              v-if="getInfoType != 'Baskets'"
            >
              <button
                class="rounded-sm text-capitalize fsize10 px-3 py-1"
                :class="item.Trantype == 'B' ? 'buycolor' : 'sellcolor'"
              >
                {{ item.Trantype == "B" ? "BUY" : "SELL" }}
              </button>
            </td>
            <td :id="`${index}_orders_table_tbody_scrip_name`"
              class="text-left pos-reletive px-4 py-2 tableContent primaryColor"
              v-if="getInfoType != 'Baskets'"
            >
              {{ item.formatScripName }}
              <span class="fsize10 secondaryColor">{{ item.exch }}</span>
              <div
                class="dropdown position-absolute right-0  d-flex top-11"
                v-if="currentIndex == index"
              >
              <label class="hoverBtn mr-2 buy positionHoverBtn" v-if="getInfoType == 'Pending'" :id="`${index}_orders_table_tbody_buy_or_sell_modify`"  @click="callOrderWindow(item.Trantype == 'B' ? 'buy' : 'sell',item,'modify');">Modify</label>
                 <label class="hoverBtn mr-2 sell positionHoverBtn"  v-if="getInfoType != 'Pending'" :id="`${index}_orders_table_tbody_repeat`"   @click="callOrderWindow(item.Trantype == 'B' ? 'buy' : 'sell', item, 'Repeat');">Repeat</label>
                 <label class="hoverBtn mr-2 positionHoverBtn" :class="$vuetify.theme.dark ? 'white--text darkHover' : 'lightHover'" :id="`${index}_orders_table_tbody_cancel`"  v-if="getInfoType == 'Pending'"   @click="cancelOrderPopup(item, index);">Cancel</label>
                <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn :id="`${index}_orders_table_tbody_opt`"
                      @mouseover="getWindowHeight(`${index}_orders_table_tbody_opt`)"
                      @click="isDropped = !isDropped"
                      min-width="50"
                      max-width="50"
                      height="23"
                      outlined
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      class="
                        d-flex
                        align-center
                        justify-center
                        pos-reletive
                        fsize12
                        text-capitalize
                        border-0075e1
                        rounded-sm
                        color-0075e1
                        background-white
                      "
                      >More
                    </v-btn>
                  </template>
                  <span>Options</span>
                </v-tooltip>

                <transition name="slide">
                  <ul :id="`${index}_orders_table_tbody_opt_ul`"
                    v-if="currentIndex == index && isDropped"
                    :class="isRotate ? 'bottom25' : ''"
                    class="pa-0 list expectMkWatchMoreList ml-4 mt-6 right-0"
                  >
                    <li :id="`${index}_orders_table_tbody_opt_li_Info`"
                      @click="
                        getInfo(item, index);
                        isDropped = false;
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Info
                    </li>
                    <li :id="`${index}_orders_table_tbody_opt_li_chart`"
                      @click="
                        isDropped = false;
                        callTrandingViewChart(item, 'order');
                      "
                      class="primaryColor fsize12 cursor"
                    >
                      Chart
                    </li>
                  </ul>
                </transition>
              </div>
            </td>
            <td
              class="text-left px-4 py-2 tableContent primaryColor"
              v-if="getInfoType != 'Baskets'" :id="`${index}_orders_table_tbody_pcode`"
            >
              {{ `${item.Pcode} / ${item.Prctype}` }}
            </td>
            <td
              class="text-right px-4 py-2 tableContent primaryColor"
              v-if="getInfoType == 'Pending' || getInfoType == 'Executed'" :id="`${index}_orders_table_tbody_qty`"
            >
              {{ item["Fillshares"] + " / " + item["Qty"] }}
            </td>
            <td
              class="text-right px-4 py-2 tableContent primaryColor"
              v-if="getInfoType == 'Trade Book'" :id="`${index}_orders_table_tbody_qty`"
            >
              {{ item["Filledqty"] + " / " + item["Qty"] }}
            </td>
            <td
              class="text-right px-4 py-2 tableContent font-weight-600 primaryColor"
              v-if="getInfoType == 'Pending'" :id="`${index}_orders_table_tbody_ltp`"
            >
              {{
                isNaN(parseFloat(item.ltp))
                  ? item.ltp
                  : item.exch == "CDS" || item.exch == "BCD"
                  ? parseFloat(item.ltp).toFixed(4)
                  : parseFloat(item.ltp).toFixed(2)
              }}
            </td>
            <td
              :class="getInfoType == 'Executed' ? 'font-weight-600' : ''"
              class="text-right px-4 py-2 tableContent primaryColor"
              v-if="getInfoType == 'Pending' || getInfoType == 'Executed'" :id="`${index}_orders_table_tbody_avg_prc`"
            >
              {{
                item.exch == "CDS" || item.exch == "BCD"
                  ? parseFloat(
                      Number(item.Avgprc) == 0 ? item.Prc : item.Avgprc
                    ).toFixed(4)
                  : parseFloat(
                      Number(item.Avgprc) == 0 ? item.Prc : item.Avgprc
                    ).toFixed(2)
              }}<span v-if="parseFloat(item.Trgprc) > 0"
                >&nbsp;/&nbsp;{{
                  item.exch == "CDS" || item.exch == "BCD"
                    ? parseFloat(item["Trgprc"]).toFixed(4)
                    : parseFloat(item["Trgprc"]).toFixed(2)
                }}
                trg.</span
              >
            </td>
            <td
              class="text-right px-4 py-2 tableContent font-weight-600 primaryColor"
              v-if="getInfoType == 'Trade Book'" :id="`${index}_orders_table_tbody_prc`"
            >
              {{
                item.exch == "CDS" || item.exch == "BCD"
                  ? parseFloat(item.Price).toFixed(4)
                  : parseFloat(item.Price).toFixed(2)
              }}
            </td>
            <td
              class="text-center px-4 py-2 tableContent primaryColor"
              v-if="getInfoType == 'Pending' || getInfoType == 'Executed'" :id="`${index}_orders_table_tbody_reason`"
            >
              <v-tooltip
                transition="scroll-y-reverse-transition"
                :disabled="
                  item.RejReason == '--' ||
                  item.RejReason == '' ||
                  item.RejReason == null
                "
                max-width="300"
                top
                :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <button
                    v-bind="attrs"
                    v-on="on"
                    class="rounded text-capitalize fsize10 min-width-100"
                    :class="
                      item.Status == 'rejected'
                        ? 'rejected-msg'
                        : item.Status == 'complete'
                        ? 'completed-msg'
                        : 'cancelled-msg'
                    "
                  >
                    {{
                      (item.Status == "cancelled after market order"
                        ? "Cancelled AMO"
                        : item.Status ==
                          "modify after market order req received"
                        ? "Modified AMO"
                        : item.Status == "after market order req received"
                        ? "AMO"
                        : item.Status
                      ).toUpperCase()
                    }}
                  </button>
                </template>
                <span class="fsize12 ">{{ item.RejReason }}</span>
              </v-tooltip>
            </td>

            <td
              class="text-left px-4 py-2 tableContent pos-reletive primaryColor"
              v-if="getInfoType == 'Baskets'" :id="`${index}_orders_table_tbody_basket_name`"
            >
              {{ item.basketName }}
            </td>
            <td :id="`${index}_orders_table_tbody_basket_size`"
              class="text-left px-4 py-2 tableContent primaryColor"
              v-if="getInfoType == 'Baskets'"
            >
              {{ item.basketSize }}
            </td>
            <td :id="`${index}_orders_table_tbody_create_time`"
              class="text-left px-4 py-2 tableContent primaryColor"
              v-if="getInfoType == 'Baskets'"
            >
              {{ item.createdTime }}
            </td>
            <td :id="`${index}_orders_table_tbody_basket_staus`"
              class="text-left px-4 py-2 tableContent d-flex primaryColor"
              v-if="getInfoType == 'Baskets'"
            >
              <button
                style="height: 24px; letter-spacing: 0.4px"
                class="rounded text-capitalize fsize10 min-width-100 "
                :class="
                  Number(item.executed) == 1 ? 'excute-msg' : 'cancelled-msg'
                "
              >
                {{ Number(item.executed) == 1 ? "EXECUTED" : "OPEN" }}
              </button>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="selectedCheckbox.length > 0 && getInfoType == 'Pending'" :id="`orders_table_tfoot`">
          <tr class="tableRow" :id="`orders_table_tfoot_tr`">
            <td class="text-left tableContent" colspan="9">
              <v-btn :id="`orders_table_tfoot_cancel_all_btn`"
                min-width="80"
                @click="$store.commit('order/setMultipleCancelDialog', true)"
                depressed
                height="32"
                :color="$store.state.buttonThemeColor"
                class="text-capitalize white--text fsize14 ml-0 ma-2"
                >{{
                  selectedCheckbox.length == 1
                    ? "Cancel Order"
                    : `Cancel Orders (${selectedCheckbox.length})`
                }}</v-btn
              >
            </td>
          </tr>
        </tfoot>
      </v-simple-table>

      <div v-if="$store.state.isMobileView" class="primaryColor">
        <div v-if="getInfoType != 'Baskets'">
          <div
            class="row ma-0 px-3 py-2 border-bottom-mwSearch"
            v-for="(item, index) in this.showOrderlist"
            :key="index"
            @click="callBottomSheet(item)" :id="`${index}_orders_table_div`"
          >
            <div class="col-6 pa-0 ma-0 d-flex align-center">
              <button :id="`${index}_orders_table_orderType_div`"
                class="rounded-sm text-capitalize fsize10 px-3 py-1"
                :class="item.Trantype == 'B' ? 'buycolor' : 'sellcolor'"
              >
                {{ item.Trantype == "B" ? "BUY" : "SELL" }}
              </button>

              <label :id="`${index}_orders_table_qty_label`"
                for=""
                v-if="getInfoType != 'Trade Book'"
                class="pl-2 fsize12"
              >
                {{ item["Fillshares"] + " / " + item["Qty"] }}</label
              >
              <label for="" v-else class="pl-2 fsize12" :id="`${index}_orders_table_qty_label`">
                {{ item["Filledqty"] + " / " + item["Qty"] }}</label
              >
            </div>
            <div class="col-6 pa-0 ma-0 d-flex justify-end align-center">
              <div class="mr-2 fsize12" :id="`${index}_orders_table_orderTime_val`">
                {{
                  getInfoType != "Trade Book"
                    ? item.OrderedTime != null
                      ? item.OrderedTime.split(" ")[3]
                      : item.OrderedTime
                    : item.Time.split(" ")[1]
                }}
              </div>
              <button :id="`${index}_orders_table_order_status_div`"
                v-if="getInfoType != 'Trade Book'"
                class="rounded text-capitalize fsize10 min-width-100"
                :class="
                  item.Status == 'rejected'
                    ? 'rejected-msg'
                    : item.Status == 'complete'
                    ? 'completed-msg'
                    : 'cancelled-msg'
                "
              >
                {{
                  (item.Status == "cancelled after market order"
                    ? "Cancelled AMO"
                    : item.Status == "modify after market order req received"
                    ? "Modified AMO"
                    : item.Status == "after market order req received"
                    ? "AMO"
                    : item.Status
                  ).toUpperCase()
                }}
              </button>
            </div>
            <div
              class="
                row
                pa-0
                fsize14
                mx-0
                my-2
                d-flex
                w-100
                justify-space-between
              "
            >
              <div :id="`${index}_orders_table_scrip_name_div`">
                {{ item.formatScripName }}
              </div>
              <div v-if="getInfoType != 'Trade Book'" :id="`${index}_orders_table_avg_prc_div`">
                {{
                  item.exch == "CDS" || item.exch == "BCD"
                    ? parseFloat(
                        Number(item.Avgprc) == 0 ? item.Prc : item.Avgprc
                      ).toFixed(4)
                    : parseFloat(
                        Number(item.Avgprc) == 0 ? item.Prc : item.Avgprc
                      ).toFixed(2)
                }}<span v-if="parseFloat(item.Trgprc) > 0"
                  >&nbsp;/&nbsp;{{
                    item.exch == "CDS" || item.exch == "BCD"
                      ? parseFloat(item["Trgprc"]).toFixed(4)
                      : parseFloat(item["Trgprc"]).toFixed(2)
                  }}
                  trg.</span
                >
              </div>
            </div>
            <div class="row ma-0 d-flex justify-space-between fsize12">
              <div v-if="getInfoType == 'Pending'">
                <span :id="`${index}_orders_table_order_ex`">{{ item.exch }}</span>
                <span class="px-2">{{ item.Pcode }}</span>
                <span :id="`${index}_orders_table_order_prctype_div`">{{ item.Prctype }}</span>
              </div>
              <div v-if="getInfoType == 'Pending'">
                <span :id="`${index}_orders_table_ltp_label`">LTP</span>
                <span class="px-2" :id="`${index}_orders_table_ltp_val`">{{
                  isNaN(parseFloat(item.ltp))
                    ? item.ltp
                    : item.exch == "CDS" || item.exch == "BCD"
                    ? parseFloat(item.ltp).toFixed(4)
                    : parseFloat(item.ltp).toFixed(2)
                }}</span>
              </div>

              <div v-if="getInfoType !== 'Pending'">
                <span :id="`${index}_orders_table_order_ex`">{{ item.exch }}</span>
              </div>
              <div v-if="getInfoType !== 'Pending'">
                <span class="px-2" :id="`${index}_orders_table_order_pcode`">{{ item.Pcode }}</span>
                <span :id="`${index}_orders_table_order_priceType`">{{ item.Prctype }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="getInfoType == 'Baskets'">
          <div
            class="
              row
              ma-0
              py-2
              px-3
              d-flex
              align-Center
              justify-Center
              border-bottom-mwSearch
            "
            v-for="(item, index) in this.showOrderlist"
            :key="index"
            @click="callBottomSheet(item)"
            :id="`${index}_baseket_table_div`"
          >
            <div class="col-4 pa-0 ma-0">
              <div class="fsize14" :id="`${index}_baseket_table_baseket_name_div`">{{ item.basketName }}</div>
              <div class="fsize14" :id="`${index}_baseket_table_create_time_div`">{{ item.createdTime }}</div>
            </div>
            <div
              class="col-4 pa-0 ma-0 d-flex align-center justify-center fsize14" :id="`${index}_baseket_table_baseket_size_div`"
            >
              {{ item.basketSize }} {{ item.basketSize > 1 ? "Items" : "Item" }}
            </div>
            <div
              class="col-4 pa-0 ma-0 d-flex align-center justify-center fsize14" :id="`${index}_baseket_table_baseket_status_div`"
            >
              {{ item.executed }}
            </div>
          </div>
        </div>
      </div>
    </v-card>

    <v-card class="ma-4" v-if="this.selectedBasketName && !this.orderLoader">
      <v-card-title class="px-6 pt-6 pb-2"
        >
        <span class="w-100 d-flex align-center justify-space-between">
          <span class="d-flex align-center" :id="`selected_baseket_name_div`">{{ selectedBasketName }} <span class="ml-2 cursor" @click="editBasketName(selectedBaseketItem);">
            <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                    <template v-slot:activator="{ on, attrs }">
                      <customIcon :id="`selected_edit_icon`" class="hoverImg d-flex align-center"   :name="'editIcon'" :width="'24'" :height="'24'" :color="$vuetify.theme.dark ? '#ffffff' : '#000000'" v-bind="attrs" v-on="on"  />
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
            </span></span>
        <span>
           <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                    <template v-slot:activator="{ on, attrs }">
                       <label class="cursor" v-bind="attrs" v-on="on" @click="isDropped = false;selectedBasket = [];getDelete(selectedBaseketItem, 'All');" :id="`selected_delete_icon`">
                      <customIcon  class="hoverImg d-flex align-center"   :name="'delete'" :width="'20'" :height="'20'" :color="$vuetify.theme.dark ? '#ffffff' : $store.state.iconBlackColourCode" /></label>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
        </span>
        </span>
      </v-card-title>
      <div class="px-6">
        <div
          class="row py-2 px-4 ma-0 d-flex align-center border-a8a8a8 rounded"
          id="basketDiv"
        >
          <customIcon
            class="d-flex"
            :name="'search'"
            :width="'20'"
            :height="'20'"
            :color="$vuetify.theme.dark ? '#ffffff' :'#282828'"
          />
          <v-divider vertical class="mx-2"></v-divider>
          <input
            type="text"
            name=""
            id="select_basket_search"
            v-model="orderSearch"
            :class="$vuetify.theme.dark ? 'white--text' : ''"
            class="border-none outline-none input-width"
            placeholder="Search and Add Instruments"
            ref="basketsearch"
          />
        </div>
        <v-sheet
          transition="fade-transition"
          tile
          class="orderSearchList mt-2"
          :width="sheetWidth"
          v-click-outside="onClickOrderOutside"
          v-if="basketSearch.length > 0"
          id="select_basket_search_sheet"
        >
          <ul class="pl-0" id="select_basket_search_sheet_ul">
            <li 
              class="px-2 py-3 border-bottom-light d-flex align-center cursor"
              @click="addBasketItem(item)"
              v-for="(item, index) in basketSearch"
              :key="index"
              :id="`${index}_select_basket_search_sheet_li`"
            >
              <span class="fsize14 primaryColor" :id="`${index}_select_basket_search_sheet_li_scrip_name`">{{ item.formattedInsName }}</span>
              <v-spacer></v-spacer>
              <span class="fsize12 pr-2 primaryColor" :id="`${index}_select_basket_search_sheet_li_scrip_ex`">{{ item.exch }}</span>
            </li>
          </ul>
        </v-sheet>
      </div>

      <v-simple-table
        v-if="selectedBasketList.length > 0"
        class="mt-6 px-6"
        :class="{ overflowinherit: isDropped }"
        :id="`select_basket_table`"
      >
        <thead :id="`select_basket_table_thead`">
          <tr id="select_basket_table_thead_tr">
            <th class="text-center" :id="`select_basket_type_head`">Type</th>
            <th class="fsize12 text-left" :id="`select_basket_instru_head`">Instrument</th>
            <th class="fsize12 text-center" :id="`select_basket_order_type_head`">Order type</th>
            <th></th>
            <th class="fsize12 text-right" :id="`select_basket_qty_head`">Qty.</th>
            <th class="fsize12 text-right" :id="`select_basket_price_head`">Price</th>
          </tr>
        </thead>
        <tbody id="select_basket_table_tbody">
          <tr :id="`${index}_select_basket_table_tbody_tr`"
            v-for="(item, index) in this.selectedBasketList"
            :key="index"
            @mouseenter="basketlistIndex = index"
            @mouseleave="
              basketlistIndex = -1;
              isDropped = false;
            "
          >
            <td class="text-center">
              <button :id="`${index}_select_basket_table_tbody_order_type`"
                class="rounded-sm text-capitalize fsize10 px-3 py-1"
                :class="item.transtype == 'buy' ? 'buycolor' : 'sellcolor'"
              >
                {{ item.transtype == "buy" ? "BUY" : "SELL" }}
              </button>
            </td>
            <td class="text-left pos-reletive px-4 py-2 tableContent" :id="`${index}_select_basket_table_tbody_scrip_name`">
              {{
                item.formatted_ins_name
                  ? item.formatted_ins_name
                  : item.trading_symbol
              }}
              <span class="fsize10 secondaryColor" :id="`${index}_select_basket_table_tbody_scrip_ex`">{{ item.ex }}</span>

              <div
                class="dropdown position-absolute right-0"
                v-if="basketlistIndex == index"
              >
                <v-tooltip top :color="$vuetify.theme.dark ? 'darktoolTipColor toolTipTop darkTooltipTop' : 'lighttoolTipColor toolTipTop lightTooltipTop'">
                    <template v-slot:activator="{ on, attrs }">
                       <label  class="hoverBtn mr-2 " :id="`${index}_select_basket_table_tbody_scrip_del`" :class="$vuetify.theme.dark ? 'darkHover' : 'lightHover'" v-bind="attrs" v-on="on" @click="isDropped = false;selectedBasket = [];
                        getDelete(item , 'ind')">
                      <customIcon  class="hoverImg d-flex align-center"   :name="'delete'" :width="'20'" :height="'20'" :color="$vuetify.theme.dark ? '#ffffff' : $store.state.iconBlackColourCode" /></label>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
              </div>
            </td>
            <td>
              <button :id="`${index}_select_basket_table_tbody_price_type`"
                class="rounded-sm text-capitalize fsize10 px-3 py-1 sellcolor"
                style="min-width: 62px"
              >
                {{
                  item.prctyp == "L"
                    ? "LIMIT"
                    : item.prctyp == "MKT"
                    ? "MARKET"
                    : item.prctyp == "SL"
                    ? "SL"
                    : "SL-M"
                }}
              </button>
            </td>
            <td>
              <button v-if="item.tempP_Code" :id="`${index}_select_basket_table_tbody_pcode_type`"
                class="rounded-sm text-capitalize fsize10 px-3 py-1" :class="item.tempP_Code.toLowerCase() == 'mis' ? 'misBtn' : item.tempP_Code.toLowerCase() == 'nrml' ? 'nrmlBtn' : 'cncBtn'"
                style="min-width: 52px"
              >
                {{ item.tempP_Code }}
              </button>
            </td>
            <td class="text-right" :id="`${index}_select_basket_table_tbody_qty`">{{ item.qty }}</td>
            <td class="text-right" :id="`${index}_select_basket_table_tbody_trigger_price`">
              {{
                Number(item.trigPrice) > 0
                  ? `${item.price} / ${item.trigPrice} trg.`
                  : item.price
              }}
            </td>
          </tr>
        </tbody>
        <tfoot :id="`select_basket_table_tfoot`">
          <tr class="tableRow">
            <td class="text-left tableContent py-4" colspan="4">
              <div v-if="spanMargin">
                <div class="secondaryColor fsize12 mb-1" :id="`select_basket_required_margin`">Required margin</div>
                <div class="fsize20 primaryColor" :id="`select_basket_required_margin_val`">
                  {{
                    spanMargin.span
                      ? ruppesFormat(spanMargin.span)
                      : "NA"
                  }}
                </div>
              </div>
            </td>
            <!-- <td class="text-left tableContent py-4" colspan="2">
              <div v-if="spanMargin">
                <div class="secondaryColor fsize12 mb-1">Final margin</div>
                <div class="fsize20 primaryColor">
                  {{
                    spanMargin.totalRequirement
                      ? ruppesFormat(spanMargin.totalRequirement)
                      : "NA"
                  }}
                </div>
              </div>
            </td> -->
            <td class="text-left tableContent py-4 justify-end" colspan="2">
              <div class="d-flex justify-end">
                <v-btn :id="`select_basket_excuted_btn`"
                  v-if="selectedBasketList.length > 0"
                  depressed
                  height="40"
                  width="100"
                  :loading="executeBasketLoader"
                  :disabled="executeBasketLoader"
                  class="text-capitalize rounded white--text"
                  @click="
                    basketResult.length > 0 &&
                    Number(basketResult[0].executed) == 0
                      ? $store.dispatch('order/executeBasketList')
                      : $store.dispatch('order/refreshBasketIndividual')
                  "
                  :color="$store.state.buttonThemeColor"
                  >{{
                    basketResult.length > 0 &&
                    Number(basketResult[0].executed) == 0
                      ? "Execute"
                      : "Refresh"
                  }}</v-btn
                >
                <v-btn :id="`select_basket_close_btn`"
                  depressed
                  outlined
                  height="40"
                  width="100"
                  color="#9b9b9b"
                  @click="
                    $store.commit('order/setTabList');
                    selectedBasketName = null;
                    $store.dispatch('order/getBasketList');
                  "
                  class="
                    text-capitalize
                    secondaryColor
                    rounded-sm-md
                    cancelbtn
                    ml-2
                  "
                  >Close</v-btn
                >
              </div>
            </td>
          </tr>
        </tfoot>
      </v-simple-table>

      <div
        class="d-flex flex-column justify-center align-center mt-4"
        v-if="
          (!!selectedBasketList &&
            selectedBasketList.length == 0 &&
            this.searchorder == '') ||
          (!!showOrderlist &&
            orderSearchFilter.length == 0 &&
            this.searchorder !== '')
        "
      >
        <div>
          <img :id="`select_basket_no_data_img`"
            src="../assets/images/noData.svg"
            alt=""
            class="w-300 h-170 mb-4"
          />
        </div>
        <div class="primaryColor mb-4" :id="`select_basket_no_data_content`">
          Use the search bar to add instruments
        </div>
        <div class="d-flex justify-end w-100 pr-6 pb-6">
                <v-btn :id="`select_basket_close_btn`"
                  depressed
                  outlined
                  height="40"
                  width="100"
                  color="#9b9b9b"
                  @click="
                    $store.commit('order/setTabList');
                    selectedBasketName = null;
                    $store.dispatch('order/getBasketList');
                  "
                  class="
                    text-capitalize
                    secondaryColor
                    rounded-sm-md
                    cancelbtn
                    ml-2
                  "
                  >Close</v-btn
                >
              </div>
      </div>
    </v-card>

    <div
      class="d-flex flex-column justify-center align-center vh-70"
      v-if="
        (!!showOrderlist &&
          showOrderlist.length == 0 &&
          this.searchorder == '' &&
          !this.selectedBasketName &&
          !this.orderLoader) ||
        (!!showOrderlist &&
          orderSearchFilter.length == 0 &&
          this.searchorder !== '' &&
          !this.selectedBasketName &&
          !this.orderLoader)
      "
    >
      <div>
        <img :id="`orders_no_data_img`"
          src="../assets/images/noData.svg"
          alt=""
          class="w-300 h-170 mb-4"
        />
      </div>
      <div class="primaryColor" :id="`orders_no_data_content`">NO ORDERS FOUND</div>
    </div>

    <!-- info dialog -->

    <v-dialog
      v-model="infoDialog"
      width="650px"
      transition="scroll-y-reverse-transition"
      :retain-focus="false"
    >
      <v-card class="pa-5">
        <v-slide-group v-model="infoItems" mandatory hide-arrows>
          <v-slide-item
            v-for="(item, i) in Items"
            :key="i"
            v-bind:value="item"
            v-slot="{ active, toggle }"
          >
            <v-btn :id="`${item}_btn`"
              depressed
              :color="active ? 'activeColor' : menuColors()"
              height="26"
              min-width="80"
              width="100px"
              class="
                rounded-sm
                fsize13
                mr-2
                text-center
                white--text
                text-capitalize
              "
              :value="item"
              @click="toggle"
            >
              {{ item }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
        <div v-if="infoDialog">
          <div class="d-flex align-baseline py-3">
            <button :id="`info_order_type`"
              class="rounded-sm text-capitalize fsize10 px-3 py-1"
              :class="
                this.orderHistory[0].Action == 'B' ? 'buycolor' : 'sellcolor'
              "
            >
              {{ this.orderHistory[0].Action == "B" ? "BUY" : "SELL" }}
            </button>

            <div class="mx-2 fsize14" :id="`info_order_scrip_name`">
              {{ this.orderHistory[0].Trsym }}
            </div>
            <div class="fsize9" :id="`info_order_scrip_exch`">{{ this.orderHistory[0]["exchange"] }}</div>
            <div class="mt-1 fsize14 primaryColor orderalign" :id="`info_order_nest_order_no`">
              Nest.Order.No#{{ this.orderHistory[0]["nestordernumber"] }}
            </div>
          </div>
        </div>
        <v-divider class="mx-2 mb-2"></v-divider>
        <div v-if="infoDialog">
          <div v-if="infoItems == 'Information'" class="height-250">
            <div class="py-2">
              <div class="row ma-0">
                <div class="col-6 py-4 px-4">
                  <div class="d-flex align-center justify-space-between">
                    <div class="fsize12 secondaryColor mb-2" :id="`info_order_qty_label`">Quantity</div>
                    <div class="fsize12 primaryColor mb-2" :id="`info_order_qty_val`">
                      {{ this.orderHistory[0]["Qty"] }}
                    </div>
                  </div>
                  <div class="d-flex align-center justify-space-between">
                    <div class="fsize12 secondaryColor mb-2" :id="`info_order_price_label`">Price</div>
                    <div class="fsize12 primaryColor mb-2" :id="`info_order_price_val`">
                      {{ this.orderHistory[0]["Prc"] }}
                    </div>
                  </div>
                  <div class="d-flex align-center justify-space-between">
                    <div class="fsize12 secondaryColor mb-2" :id="`info_order_avg_price`">Avg.price</div>
                    <div class="fsize12 primaryColor mb-2" :id="`info_order_avg_price_val`">
                      {{ this.orderHistory[0]["averageprice"] }}
                    </div>
                  </div>
                  <div class="d-flex align-center justify-space-between">
                    <div class="fsize12 secondaryColor mb-2" :id="`info_order_trigger_price`">Trigger price</div>
                    <div class="fsize12 primaryColor mb-2" :id="`info_order__trigger_price_val`">
                      {{ this.orderHistory[0]["triggerprice"] }}
                    </div>
                  </div>
                  <div class="d-flex align-center justify-space-between">
                    <div class="fsize12 secondaryColor mb-2" :id="`info_order_type_div`">Order type</div>
                    <div class="fsize12 primaryColor mb-2" :id="`info_order_type_div_val`">
                      {{ this.orderHistory[0]["Ordtype"] }}
                    </div>
                  </div>
                  <div class="d-flex align-center justify-space-between">
                    <div class="fsize12 secondaryColor mb-2" :id="`info_order_product_type_div`">Product</div>
                    <div class="fsize12 primaryColor mb-2" :id="`info_order_product_type_val`">
                      {{ this.orderHistory[0]["productcode"] }}
                    </div>
                  </div>
                </div>

                <v-divider vertical></v-divider>

                <div class="col-6 py-4 px-4">
                  <div class="d-flex align-center justify-space-between">
                    <div class="fsize12 secondaryColor mb-2" :id="`info_order_validity_type_div`">Validity</div>
                    <div class="fsize12 primaryColor mb-2" :id="`info_order_validity_type_div_val`">
                      {{ this.orderHistory[0]["duration"] }}
                    </div>
                  </div>
                  <div class="d-flex align-center justify-space-between">
                    <div class="fsize12 secondaryColor mb-2" :id="`info_order_Id`">
                      Exch. Order ID
                    </div>
                    <div class="fsize12 primaryColor mb-2" :id="`info_order_Id_val`">
                      {{
                        orderHistory[0].exchangeorderid == null
                          ? "NA"
                          : orderHistory[0].exchangeorderid
                      }}
                    </div>
                  </div>
                  <div class="d-flex align-center justify-space-between">
                    <div class="fsize12 secondaryColor mb-2" :id="`info_time`">Time</div>
                    <div class="fsize12 primaryColor mb-2" :id="`info_time_val`">
                      {{ this.orderHistory[0]["filldateandtime"] }}
                    </div>
                  </div>
                  <div class="d-flex align-center justify-space-between">
                    <div class="fsize12 secondaryColor mb-2" :id="`info_exch_time`">Exch. Time</div>
                    <div class="fsize12 primaryColor mb-2" :id="`info_exch_time_val`">
                      {{
                        orderHistory[0].ExchTimeStamp != null
                          ? orderHistory[0].ExchTimeStamp.split(" ")[1]
                          : orderHistory[0].ExchTimeStamp
                      }}
                    </div>
                  </div>
                  <div class="d-flex align-center justify-space-between">
                    <div class="fsize12 secondaryColor mb-2" :id="`info_placed_by`">Placed by</div>
                    <div class="fsize12 primaryColor mb-2" :id="`info_placed_by_val`">
                      {{ getUserId }}
                    </div>
                  </div>
                </div>
                <div class="d-flex align-center justify-space-between">
                  <div class="fsize12 secondaryColor mb-2 mt-1 px-4" :id="`info_reason_div`">
                    Reason: &nbsp;
                  </div>
                  <div
                    class="fsize12 primaryColor mb-2 mt-1" :id="`info_reason_val`"
                    v-if="
                      orderHistory[0].rejectionreason != undefined &&
                      orderHistory[0].rejectionreason != null &&
                      orderHistory[0].rejectionreason != ''
                    "
                  >
                    {{ this.orderHistory[0]["rejectionreason"] }}
                  </div>
                </div>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
        </div>

        <div v-if="infoItems == 'History'" class="height-250">
          <v-simple-table class="px-3" id="order_history_table">
            <thead id="order_history_table_thead">
              <tr id="order_history_table_thead_tr">
                <th class="text-left fsize12 secondaryColor" id="order_history_table_thead_time">Time</th>
                <th class="text-center fsize12 secondaryColor" id="order_history_table_thead_staus">Status</th>
                <th class="text-center fsize12 secondaryColor" id="order_history_table_thead_exch_time">Exch. time</th>
                <th class="text-center fsize12 secondaryColor" id="order_history_table_thead_exch_update">Exch. update</th>
                <th class="text-center fsize12 secondaryColor" id="order_history_table_thead_qty">Qty</th>
                <th class="text-center fsize12 secondaryColor" id="order_history_table_thead_filled_qty">Filled qty</th>
                <th class="text-center fsize12 secondaryColor" id="order_history_table_thead_avg_price">Avg. price</th>
                <th class="text-center fsize12 secondaryColor" id="order_history_table_thead_price">Price</th>
              </tr>
            </thead>
            <tbody id="order_history_table_tbody">
              <tr v-for="(item, index) in orderHistory" :key="index" :id="`${index}_order_history_table_tbody`">
                <td class="text-left fsize12 primaryColor" :id="`${index}_order_history_table_time_stamp`">
                  {{
                    item.ExchTimeStamp != null
                      ? item.ExchTimeStamp.split(" ")[1]
                      : item.ExchTimeStamp
                  }}
                </td>
                <td class="text-center fsize12 primaryColor" :id="`${index}_order_history_table_status`">
                  {{ item.Status }}
                </td>
                <td class="text-center fsize12 primaryColor" :id="`${index}_order_history_table_next_exch_time`">
                  {{
                    item.exchangetimestamp != null
                      ? item.exchangetimestamp.split(" ")[1]
                      : item.exchangetimestamp
                  }}
                </td>
                <td class="text-center fsize12 primaryColor" :id="`${index}_order_history_table_filed_date_time`">
                  {{ item.filldateandtime }}
                </td>
                <td class="text-center fsize12 primaryColor" :id="`${index}_order_history_table_qty`">
                  {{ item.Qty }}
                </td>
                <td class="text-center fsize12 primaryColor" :id="`${index}_order_history_table_filled_shares`">
                  {{ item.filledShares }}
                </td>
                <td class="text-center fsize12 primaryColor" :id="`${index}_order_history_table_avg_price`">
                  {{ item.averageprice }}
                </td>
                <td class="text-center fsize12 primaryColor" :id="`${index}_order_history_table_price`">
                  {{ item.Prc }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
        <div class="row pt-4 ma-0 d-flex justify-end">
          <v-btn :id="`info_dialog_table_close_btn`"
            depressed
            outlined
            class="text-capitalize"
            width="60px"
            height="32px"
            @click="infoDialog = false"
            >Close</v-btn
          >
        </div>
      </v-card>
    </v-dialog>

    <!-- cancel order popup-->

    <v-dialog v-model="multipleCancelDialog" width="700px">
      <v-expand-transition>
        <v-card class="pa-5">
          <div class="pb-4" id="multiple_cancel_label">Cancel Order</div>
          <div class="">
            <v-simple-table class="border-ededed" id="multiple_cancel_table">
              <thead id="multiple_cancel_table_thead">
                <tr id="multiple_cancel_table_thead_tr">
                  <th id="multiple_cancel_table_thead_th"></th>
                  <th class="text-left" id="multiple_cancel_table_thead_instrument"><span>Instrument</span></th>
                  <th class="text-right" id="multiple_cancel_table_thead_qty"><span>Qty</span></th>
                  <th class="text-center" id="multiple_cancel_table_thead_price"><span>Price</span></th>
                  <th class="text-left" id="multiple_cancel_table_thead_type"><span>Type</span></th>
                  <th class="text-left" id="multiple_cancel_table_thead_product"><span>Product</span></th>
                  <th class="text-left" id="multiple_cancel_table_thead_validity"><span>Validity</span></th>
                </tr>
              </thead>
              <tbody id="multiple_cancel_table_tbody">
                <tr v-for="(item, index) in this.selectedCheckbox" :key="index" :id="`${index}_multiple_cancel_table_tbody_tr`">
                  <td class="text-center" :id="`${index}_multiple_cancel_table_tbody_cancel_status`">
                    <span class="cancelled-msg px-2 py-1 rounded fsize11"
                      >CANCEL</span
                    >
                  </td>

                  <td class="text-left" :id="`${index}_multiple_cancel_table_tbody_scrip_name`">
                    {{ item.formatScripName }}
                    <span class="fsize10 secondaryColor" :id="`${index}_multiple_cancel_table_tbody_scrip_ex`">{{ item.exch }}</span>
                  </td>

                  <td class="text-right" :id="`${index}_multiple_cancel_table_tbody_scrip_qty`">
                    <span>{{ item.Qty }}</span>
                  </td>

                  <td class="text-center" :id="`${index}_multiple_cancel_table_tbody_scrip_avg_prc`">
                    <span>{{
                      item.exch == "CDS" || item.exch == "BCD"
                        ? parseFloat(Number(item.Avgprc) == 0 ? item.Prc : item.Avgprc).toFixed(4)
                        : parseFloat(Number(item.Avgprc) == 0 ? item.Prc : item.Avgprc).toFixed(2)
                    }}</span>
                  </td>

                  <td class="text-left" :id="`${index}_multiple_cancel_table_tbody_scrip_prc_type`">
                    <span>{{ item.Prctype }}</span>
                  </td>

                  <td class="text-left" :id="`${index}_multiple_cancel_table_tbody_scrip_Pcode`">
                    <span>{{ item.Pcode }}</span>
                  </td>

                  <td class="text-left" :id="`${index}_multiple_cancel_table_tbody_scrip_day`">
                    <span>DAY</span>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div class="text-right pt-4">
            <v-btn :id="`multiple_cancel_confirm_btn`"
              height="32"
              min-width="80"
              depressed
              :color="$store.state.buttonThemeColor"
              class="fsize14 mr-2 text-capitalize white--text"
              :loading="singleCancelLoader"
              :disabled="singleCancelLoader"
              @click="cancelOrder(selectedCheckbox, 'multiple')"
              >Confirm</v-btn
            >
            <v-btn :id="`multiple_cancel_close_btn`"
              outlined
              height="32"
              min-width="80"
              color="#9b9b9b"
              depressed
              class="fsize14 text-capitalize secondaryColor"
              @click="$store.commit('order/setMultipleCancelDialog', false)"
            >
              Close
            </v-btn>
          </div>
        </v-card>
      </v-expand-transition>
    </v-dialog>

    <!-- single order cancel popup-->

    <v-dialog
      class="singleCancelDialog"
      v-model="singleCancelDialog"
      width="500px"
    >
      <v-expand-transition>
        <v-card class="pa-5" id="single_cancel_dialog_card">
          <div class="pb-4 fsize16 font-weight-bold" id="single_cancel_dialog_card_content">Cancel the Order?</div>
          <div class="text-left font-weight-bold mb-1 fsize14" id="single_cancel_dialog_scrip_name">
            {{ cancelarray.formatScripName }}
          </div>
          <div class="text-left fsize13" id="single_cancel_dialog_nstorNo">#{{ cancelarray.Nstordno }}</div>
          <div class="text-right pt-4">
            <v-btn id="single_confirm_btn"
              height="32"
              min-width="80"
              depressed
              :loading="singleCancelLoader"
              :disabled="singleCancelLoader"
              :color="$store.state.buttonThemeColor"
              class="fsize14 text-capitalize white--text"
              @click="cancelOrder(cancelarray, 'single')"
              >Confirm</v-btn
            >
            <v-btn id="single_close_btn"
              outlined
              height="32"
              min-width="80"
              depressed
              class="ml-2 fsize14 text-capitalize"
              @click="$store.commit('order/setSingleCancelDialog', false)"
            >
              Close
            </v-btn>
          </div>
        </v-card>
      </v-expand-transition>
    </v-dialog>

    <!-- create Basket Dialog  -->
    <v-dialog
      v-model="createBasketDialog"
      width="400px"
      transition="slide-y-transition"
    >
      <v-card id="create_basket_dialog_card">
        <v-card-title id="create_basket_dialog_card_title"> Create Basket </v-card-title>
        <v-divider class="mx-6 mb-4"></v-divider>
        <v-card-text>
          <form action="" @submit.prevent="createNewBasket">
            <v-text-field
              type="text"
              label="Name"
              ref="Name"
              id="new_baseket_inp"
              autofocus
              v-model.trim="baskeName"
              outlined
              hide-details
              :error-messages="errorBasketName"
              :required="false"
              dense
              class="mt-2 fsize13"
            ></v-text-field>
            <div class="red--text fsize12 mt-1 ml-2 h-16" id="new_baseket_err_msg">
              <span v-if="errorBasketName">{{ errorBasketName }}</span>
            </div>
          </form>
        </v-card-text>
        <v-card-actions class="pa-0">
          <div class="mx-6 w-100 pb-4 d-flex align-center justify-end">
            <v-btn id="new_baseket_confirm_btn"
              height="32"
              min-width="80"
              depressed
              :disabled="createBasketLoader"
              type="submit"
              :loading="createBasketLoader"
              :color="$store.state.buttonThemeColor"
              class="fsize14 text-capitalize white--text"
              @click="createNewBasket"
              >{{ isEdit ? "Update" : "Create" }}</v-btn
            >
            <v-btn id="new_basket_close_btn"
              outlined
              height="32"
              min-width="80"
              depressed
              class="ml-2 fsize14 text-capitalize"
              @click="createBasketDialog = false"
            >
              Close
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" width="400px">
      <v-card id="delete_baseket_card">
        <div class="pb-2 px-4 pt-4 d-flex align-center justify-space-between" id="delete_baseket_name">
          {{
            basketWhere && basketWhere != 'All' &&selectedBasketName 
              ? `Are sure to delete "${
                  selectedBasket.trading_symbol
                    ? selectedBasket.trading_symbol
                    : selectedBasket.formatted_ins_name
                }" scrip?`
              : `Are sure to delete "${selectedBasket.basketName}" basket?`
          }}
        </div>

        <div class="px-4 pt-2 pb-4 pt-8 d-flex align-end justify-end">
          <v-btn id="delete_scrip_confirm_btn"
            depressed
            @click="deleteBasket"
            class="flat text-capitalize fsize14 white--text sellColor"
            >Yes, Delete</v-btn
          >
          <v-btn id="delete_scrip_close_btn"
            depressed
            color="#fff"
            outlined
            @click="deleteDialog = false"
            class="flat text-capitalize fsize14  ml-3"
            :class="$vuetify.theme.dark ? '' : 'black--text'"
            >Cancel</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <bottomsheet
      :currentData="currentSheetData"
      v-on:from-child="getChildData"
    />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import window from "../mixins/orderWindow";
import commonFunc from "../mixins/commonFunctions";
import bottomsheet from "../components/bottomSheet.vue";
import customIcon from "../components/customIcon.vue";
import alert from "../components/alert.vue"
export default {
  mixins: [commonFunc, window],
  data: () => ({
    searchorder: "",
    selectedCheckbox: [],
    Items: ["Information", "History"],
    infoItems: "Information",
    isDropped: false,
    currentIndex: -1,
    basketlistIndex: -1,
    cancelarray: [],
    isRotate: false,
    currentSheetData: [],
    isEdit: false,
    selectedBasket: [],
    deleteDialog: false,
    sheetWidth: 0,
    basketWhere: '',
    selectedBaseketItem: []
  }),
  components: {
    bottomsheet,
    customIcon,
    alert
  },

  computed: {
    ...mapState("order", [
      "pendingOrderList",
      "completedOrderList",
      "tradeBookList",
      "showOrderlist",
      "tabList",
      "orderHistory",
      "orderLoader",
      "singleCancelLoader",
      "cancelMultiple",
      "createBasketLoader",
      "selectedBasketList",
      "basketSearch",
      "spanMargin",
      "basketOrderList",
      "basketResult",
      "executeBasketLoader",
      "alertDetails"
    ]),
    ...mapGetters("order", ["getTradeList", "getInfoType"]),
    ...mapGetters("position", [{ getInfoTypePosition: "getInfoType" }]),
    ...mapGetters("position", [{ getInfoTypePosition: "getInfoType" }]),
    ...mapGetters("authentication", ["getUserId"]),
    ...mapGetters("order", [
      "getSingleCancelDialog",
      "getMultipleCancelDialog",
    ]),

    infoType: {
      get: function () {
        return this.getInfoType;
      },
      set: function (value) {
        if (value == "Pending" || value == "Executed") {
           this.selectedCheckbox = [];
          this.$store.dispatch("order/getOrderList");
         
        }
        this.$store.commit("order/setTabActive", value);
      },
    },

    checkBoxSelectAll: {
      get: function () {
        this.$store.commit("order/setMultiple", this.selectedCheckbox.length);
        return this.showOrderlist
          ? this.selectedCheckbox.length == this.showOrderlist.length
          : false;
      },
      set: function (value) {
        var selected = [];
        if (value) {
          this.showOrderlist.forEach(function (item) {
            selected.push(item);
          });
        } else {
          this.cancelMultiple == 0 ? (this.selectedCheckbox = []) : "";
        }
        this.selectedCheckbox = selected;
      },
    },

    orderSearchFilter() {
      return this.showOrderlist.filter((post) => {
        return this.getInfoType == "Trade Book"
          ? post.Tsym.toLowerCase().includes(this.searchorder.toLowerCase())
          : this.getInfoType == "Baskets"
          ? post.basketName
              .toLowerCase()
              .includes(this.searchorder.toLowerCase())
          : post.Trsym.toLowerCase().includes(this.searchorder.toLowerCase());
      });
    },

    singleCancelDialog: {
      get() {
        return this.getSingleCancelDialog;
      },
      set(value) {
        if (!value) {
          this.$store.commit("order/setSingleCancelDialog", value);
        }
        return value;
      },
    },
    createBasketDialog: {
      get() {
        return this.$store.state.order.createBasketDialog;
      },
      set(val) {
        this.$store.commit("order/setCreateBasketDialog", val);
      },
    },

    multipleCancelDialog: {
      get() {
        return this.getMultipleCancelDialog;
      },
      set(value) {
        if (!value) {
          this.$store.commit("order/setMultipleCancelDialog", value);
          return value;
        }
      },
    },
    baskeName: {
      get() {
        return this.$store.state.order.baskeName;
      },
      set(val) {
        this.$store.commit("order/setBaskeName", val);
      },
    },
    errorBasketName: {
      get() {
        return this.$store.state.order.errorBasketName;
      },
      set(val) {
        this.$store.commit("order/setErrorBasketName", val);
      },
    },
    orderSearch: {
      get() {
        return this.$store.state.order.orderSearch;
      },
      set(value) {
        value
          ? (this.sheetWidth = document
              .getElementById("basketDiv")
              .getBoundingClientRect().width)
          : "";
        this.$store.commit("order/setOrderSearch", value);
        var temp = {
          value: value.toString().toUpperCase(),
          where: "order",
        };
        value.length > 1
          ? this.$store.dispatch("marketWatch/getSearchScripts", temp)
          : "";
      },
    },
    selectedBasketName: {
      get() {
        return this.$store.state.order.selectedBasketName;
      },
      set(val) {
        this.$store.commit("order/setSelectedBasketName", val);
      },
    },
    infoDialog: {
      get() {
        return this.$store.state.order.infoDialog;
      },
      set(val) {
        this.$store.commit("order/setInfoDialog", val);
      },
    },
  },

  methods: {
    callService(val) {
      val == "Trade Book" ? this.$store.dispatch("order/fetchTradeList") : "";
      val != "Baskets"
        ? (this.selectedBasketName = null)
        : this.$store.dispatch("order/getBasketList");
      val != "Baskets" ? this.$store.commit("order/setTabList") : "";

      if (val == "Alerts") {
          this.$store.dispatch("order/getAlertDetails");
        }
    },
    onClickOrderOutside() {
      this.orderSearch = "";
      this.$store.commit("order/setBasketSearch", []);
    },
    editBasket(item) {
      this.selectedBasket = [];
      this.$store.commit("order/setSelectedBaskList", []);
      let json = {
        basketId: item.basketId,
      };
      var temp = {
        json: json,
        basketName: item.basketName,
      };
      this.selectedBasketName = item.basketName;
      this.onClickOrderOutside();
      this.$store.dispatch("order/getBasketDetails", temp);
    },
    editBasketName(val) {
      this.selectedBasket = val;
      this.baskeName = this.selectedBasketName;
      this.isEdit = true;
      this.createBasketDialog = true;
    },
    createNewBasket() {
      if (this.baskeName) {
        this.errorBasketName = "";
        let json = {
          basketName: this.baskeName,
        };

        if(this.isEdit){
          json.basketId = this.selectedBasket.basketId
          this.setBasket(this.selectedBasket)
          this.$store.dispatch("order/updateBasketName", json)
        }else{
          this.$store.dispatch("order/createBasket", json)
        }
        this.createBasketDialog = false;
        this.baskeName = "";
      } else {
        this.errorBasketName = "Please Enter Your Basket Name";
      }
    },
    setBasket(item){
      this.selectedBaseketItem = item
      this.getDelete(item, null)
      this.editBasket(item)
    },
    getDelete(item , where) {
      this.basketWhere = where
      this.selectedBasket = item;
      where ? this.deleteDialog = true : ''
    },
    deleteBasket() {
      let lson = {
        basketId: this.selectedBasket.basketId,
      };
      if (this.selectedBasketName && this.basketWhere && this.basketWhere != 'All') {
        let selectedIndex = this.basketOrderList.findIndex(
          (std) => std.basketName == this.selectedBasketName
        );
        lson.basketName = this.basketOrderList[selectedIndex].basketName;
        lson.basketId = this.basketOrderList[selectedIndex].basketId;
        const index = this.selectedBasketList.indexOf(this.selectedBasket);
        if (index != -1) {
          this.selectedBasketList.splice(index, 1);
        }
        lson.basketInput = this.selectedBasketList;
      }


        
        if(this.selectedBasketName && this.basketWhere == 'All'){
          this.infoType = 'Baskets'
          this.$store.dispatch("order/deleteBasket", lson);
          this.selectedBasketName = false
        }else{
          this.$store.dispatch("order/createBasket", lson)
        }

      this.deleteDialog = false;
    },
    addBasketItem(item) {
      this.onClickOrderOutside();
      this.callOrderWindowByContract(item, "buy", false, "basket", true, false, false);
    },
    getChildData(data) {
      if (data["page"] == "Order") {
        const index = this.showOrderlist.indexOf(data.item);
        if (
          data["action"] == "Repeat" ||
          data["action"] == "modify"
        ) {
             this.callOrderWindow(
                data["item"].Trantype == "B" ? "buy" : "sell",
                data.item,
                data["action"]
              )
        }
        if (data["action"] == "cancel") {
          this.cancelOrderPopup(data.item, index);
        }
        if (data["action"] == "info") {
          this.getInfo(data.item, index);
        }
        if (data["action"] == "chart") {
          this.callTrandingViewChart(data.item, "order");
        }
        if (data["action"] == "edit") {
          this.editBasketName(data.item);
        }
        if (data["action"] == "Details") {
          this.editBasket(data.item);
        }
        if (data["action"] == "delete") {
          this.selectedBasket = [];
          this.getDelete(data.item, 'All');
        }
      }
    },
    callBottomSheet(val) {
      this.currentSheetData = [];
      var tempData = {
        where: "Order",
        isOpen: true,
        item: val,
        tab: this.infoType,
      };
      this.currentSheetData.push(tempData);
    },
    getWindowHeight(id) {
      var offsetHeight =
        this.$store.state.windowHeight -
        document.getElementById(id).getBoundingClientRect().top;
      var dropdownHeight = 125;
      offsetHeight < dropdownHeight
        ? (this.isRotate = true)
        : (this.isRotate = false);
    },
    getInfo(item, index) {
      this.$store.dispatch("order/getOrderHistory", item);
      this.infoItems = 0;
    },
    async callOrderWindow(orderType, value, type) {
      let isModify;
      let isRepeat;
      type == "modify" ? (isModify = true) : (isModify = false);
      type == "Repeat" ? isRepeat = true : isRepeat = false
      this.callOrderWindowByContract(
        value,
        orderType,
        isModify,
        "orders",
        false,
        false,
        isRepeat
      );
    },

    cancelOrderPopup(val) {
      this.cancelarray = val;
      this.$store.commit("order/setSingleCancelDialog", true);
    },
    async cancelOrder(val, type) {
      if (type == "single") {
        (await val.Pcode) == "BO"
          ? this.$store.dispatch("order/cancelBraketOrder", val)
          : val.Pcode == "CO"
          ? this.$store.dispatch("order/cancelCoverOrder", val)
          : this.$store.dispatch("order/cancelOrder", val);
      } else {
        for (let item of this.selectedCheckbox) {
          (await item.Pcode) == "BO"
            ? this.$store.dispatch("order/cancelBraketOrder", item)
            : item.Pcode == "CO"
            ? this.$store.dispatch("order/cancelCoverOrder", item)
            : this.$store.dispatch("order/cancelOrder", item);
        }
      }
    },
    listenMwTicks(event) {
      var tickerData = event.detail.storage;
      let token = this.$store.state.wsConnection.validToken
      var selectedArray = this.pendingOrderList.filter((el) => {
        return el.token == token;
      });
        if(token && selectedArray.length > 0){
          for(let element of selectedArray){
          var responseFeed = tickerData[element.token];
            element.ltp = responseFeed?.lp ? responseFeed.lp : element.ltp;
            element.o = responseFeed?.o ? responseFeed.o : element.o;
            element.h = responseFeed?.h ? responseFeed.h : element.h;
            element.l = responseFeed?.l ? responseFeed.l : element.l;
            element.volume = responseFeed?.v ? responseFeed.v : element.volume;
            let index = this.pendingOrderList.indexOf(element)
            this.pendingOrderList[index] = element
          }
        }
    },
  },
  watch: {
    cancelMultiple: function (val) {
      if (val == 0) {
        this.selectedCheckbox = [];
      }
    },
    baskeName: function (val) {
      !val && this.createBasketDialog
        ? (this.errorBasketName = "Please Enter Your Basket Name")
        : (this.errorBasketName = "");
    },
    createBasketDialog: function (val) {
      if (!val) {
        this.baskeName = this.errorBasketName = "";
      }
    },
  },

  async created() {
    await this.$store.dispatch("order/getOrderList");
    var localTab;
    localStorage.getItem("orderTab") &&
    localStorage.getItem("orderTab") != "undefined"
      ? (localTab = JSON.parse(localStorage.getItem("orderTab")))
      : (localTab = "Pending");
    this.$route.query.tab
      ? this.$store.commit("order/setTabActive", this.$route.query.tab)
      : this.$store.commit("order/setTabActive", localTab);
    this.callService(this.$route.query.tab ? this.$route.query.tab : localTab);
  },
};
</script>